import React from 'react';
import DoneIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/IndeterminateCheckBox';
import LoadingIcon from '@mui/icons-material/Cached';
import EmptyIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const LoadingState = ({ loadingState }) =>  (
  <>
    {Object.values(loadingState).map(client => (
      <div style={{ display: 'flex', flexDirection: 'row' }} key={client.name}>
        {client.loading ? <LoadingIcon style={{ animation: 'rotation 2s infinite linear reverse'}} /> : client.error ? <ErrorIcon /> : client.done ? <DoneIcon /> : <EmptyIcon />}
        {client.name}
      </div>
    ))}
  </>
);

export default LoadingState;